import React, { useState } from 'react'

import { Link } from 'gatsby'

import { Helmet } from 'react-helmet'

// import HamburgerMenu from 'react-hamburger-menu';

import '../styles/layout-styles.css'

const Layout = ({ children }) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)

  // const handleHamburgerClick = () => {
  //     setMobileNavIsOpen((current) => !current);
  // }

  return (
    <div className='globalStyles'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Velvet Brown</title>
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css?family=Domine'
        />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css?family=Roboto'
        />
      </Helmet>
      <>
        <header className='headerWrapper'>
          <div className='flex text-white justify-center xs:space-x-0 py-5 md:py-7 lg:justify-start lg:mx-32'>
            <span>
              <Link
                className='text-white text-2xs px-1 xs:px-1.5 xs:text-xs md:text-lg md:px-5 hidden md:inline'
                to='/'
              >
                Velvet Brown
              </Link>
            </span>
            <span>
              <Link
                className='text-white text-2xs px-1 xs:px-1.5 xs:text-xs md:text-lg md:px-5 md:hidden'
                to='/'
              >
                Home
              </Link>
            </span>
            <span>
              <Link
                className='text-white text-2xs px-1 xs:px-1.5 xs:text-xs md:text-lg md:px-5'
                to='/bio'
              >
                Biography
              </Link>
            </span>
            <span>
              <Link
                className='text-white text-2xs px-1 xs:px-1.5 xs:text-xs md:text-lg md:px-5'
                to='/music'
              >
                Discography
              </Link>
            </span>
            <span>
              <Link
                className='text-white text-2xs px-1 xs:px-1.5 xs:text-xs md:text-lg md:px-5'
                to='/publications'
              >
                Publications
              </Link>
            </span>
            <span>
              <Link
                className='text-white text-2xs px-1 xs:px-1.5 xs:text-xs md:text-lg md:px-5'
                to='/videos'
              >
                Videos
              </Link>
            </span>
            <span>
              <Link
                className='text-white text-2xs px-1 xs:px-1.5 xs:text-xs md:text-lg md:px-5'
                to='/photos'
              >
                Photos
              </Link>
            </span>
          </div>
        </header>
        {!mobileNavIsOpen && children}
        {mobileNavIsOpen && (
          <div className='mobileNav'>
            <span className='mobileNavItem'>
              <Link to='/'>Velvet Brown</Link>
            </span>
            <span className='mobileNavItem'>
              <Link to='/bio'>Biography</Link>
            </span>
            <span className='mobileNavItem'>
              <Link to='/music'>Discography</Link>
            </span>
            <span className='mobileNavItem'>
              <Link to='/publications'>Publications</Link>
            </span>
            <span className='mobileNavItem'>
              <Link to='/videos'>Videos</Link>
            </span>
            <span className='mobileNavItem'>
              <Link to='/photos'>Photos</Link>
            </span>
          </div>
        )}
      </>
    </div>
  )
}

export default Layout
